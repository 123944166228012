import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';


const JobDetails = ({ slug }) => {
  const [jobData, setJobData] = useState(null);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobileNumber: '',
    post_jobs_id: '',
    cv: null
  });


  const location = useLocation()

  const newloaction = location.pathname.split('/')[2]
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(`https://admin.autorentmotorcycle.com/api/getJobDetailsByJobSlug/${newloaction}`);
        setJobData(response.data.data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          post_jobs_id: response.data.data.id
        }));
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchJobDetails();
  }, [slug]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, cv: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      await axios.post('https://admin.autorentmotorcycle.com/api/applyJob', formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      alert("Application submitted successfully!");
    } catch (error) {
      console.error("Error submitting application:", error);
      alert("Failed to submit application.");
    }
  };

  return (
    <Container className='mt-5 mb-5'>
      <div className="row">
        <div className="col-md-6 carrer-job-desc">
          {jobData ? (
            <>
              {/* <h2>{jobData.position.position}</h2>
            <h3>Department: {jobData.department.department_name}</h3>
            <h5>Job Code: {jobData.job_code}</h5>
            <h6>Vacancies: {jobData.no_of_vacancy}</h6> */}

              <div className="job-details-table">
                <h2>{jobData.position.position}</h2>
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th>Department</th>
                      <td>{jobData.department.department_name}</td>
                    </tr>
                    <tr>
                      <th>Job Code</th>
                      <td>{jobData.job_code}</td>
                    </tr>
                    <tr>
                      <th>Vacancies</th>
                      <td>{jobData.no_of_vacancy}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div dangerouslySetInnerHTML={{ __html: jobData.job_description }} />
            </>
          ) : (
            <p>Loading job details...</p>
          )}
        </div>
        <div className="col-md-6 carrer-job-form">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label>Full Name</label>
              <input type="text" name="fullName" className="form-control" value={formData.fullName} onChange={handleInputChange} required />
            </div>
            <div className="mb-3">
              <label>Email</label>
              <input type="email" name="email" className="form-control" value={formData.email} onChange={handleInputChange} required />
            </div>
            <div className="mb-3">
              <label>Mobile Number</label>
              <input type="tel" name="mobileNumber" className="form-control" value={formData.mobileNumber} onChange={handleInputChange} required />
            </div>
            <div className="mb-3">
              <label>Upload pdf only</label>
              <input type="file" name="cv" className="form-control" onChange={handleFileChange} required />
            </div>
            <button type="submit" className="btn btn-primary">Submit Application</button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default JobDetails;
