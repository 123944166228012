import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

import Navbar from './Components/Navbar';
import Scrolltop from './Components/Scrolltop';
import Termsandconsitions from './Components/Termsandconsitions';
import Privacycom from './Components/Privacypolicy';
import Carrers from './Components/Carrers';
import Careerapllication from './Components/Careerapllication';
// Lazy-load components
const Homepage = lazy(() => import('./Components/Homepage'));
const About = lazy(() => import('./Components/About'));
const Gallery = lazy(() => import('./Components/Gallary'));
const Brands = lazy(() => import('./Components/Brands'));
const Contact = lazy(() => import('./Components/Contact'));
const BrandDetails = lazy(() => import('./Components/Branddetails'));
const Unicorn = lazy(() => import('./Components/Unicorn'));
const Hunk = lazy(() => import('./Components/Hunk'));
const Pulsar = lazy(() => import('./Components/Pulsar'));
const TermsAndConditions = lazy(() => import('./Components/Termsandconsitions'));
const LandingPage = lazy(() => import('./Landing_page'));

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Scrolltop />
      <Suspense fallback={<Container>
                <Row style={{ height: '650px', padding: '0px' }}>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '10%' }}>
                        {/* <Spinner style={{color:'red'}}/>  */}
                        <img src='/Asest/Homepage/giphy.gif' width={'100px'} height={'100px'} alt='altimg' />
                    </Col>
                </Row>
            </Container>}>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/brand' element={<Brands />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/brand/apache' element={<BrandDetails />} />
          <Route path='/brand/unicorn' element={<Unicorn />} />
          <Route path='/brand/hunk' element={<Hunk />} />
          <Route path='/brand/pulsar' element={<Pulsar />} />
          <Route path='/terms-and-conditionds' element={<Termsandconsitions />} />
          <Route exact path='/landing-page/:campaign/:source' element={<LandingPage />} />
          <Route path='/product/unicorn' element={<Unicorn />} />
          <Route path='/product/hunk' element={<Hunk />} />
          <Route path='/product/pulsar' element={<Pulsar />} />
          <Route path='/product/apache' element={<BrandDetails />} />
          <Route path='/privacy-policy' element={<Privacycom/>}/>
          <Route path='*' element={<Homepage/>}/>
          <Route path="/careers" element={<Carrers/>}/>
          <Route path='/careers/:jobcode' element={<Careerapllication/>}/>


        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
