import React from 'react';
import { FiInstagram} from 'react-icons/fi'
import { FaFacebookF,FaLinkedinIn,FaHome} from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import { Container,Row,Col } from 'bootstrap-4-react/lib/components/layout';
import {BsArrowRightShort} from 'react-icons/bs'
import BookNow from './BookNow';

function Header() {
  return (
   


<Container fluid className="heder-section">
   

   <Row>
    




     <Col className="header-contact"  >
       
         <p style={{margin:'0px',fontSize:'10px'}} className='book-now-btn-contact'> 
           Call us : 600 549 993  <a>  | </a> <a type="button"  data-toggle="modal" data-target="#exampleModal">
   Login </a> <a href='https://www.facebook.com/profile.php?id=100088953366323'><FaFacebookF/> </a> <a  href='https://www.instagram.com/autorentmotorcyclerental/'><FiInstagram/> </a> <a href='https://www.linkedin.com/company/91067150/admin/'><FaLinkedinIn/> </a> 
         </p>
         
    
       </Col>

       <Col  className="header-btn">
    
    <p className='book-now-btn' data-toggle="modal" data-target="#exampleModal-1"  >BOOK NOW</p>
  
   
</Col>

       </Row>
      
      
 {/* <BookNow/> */}
   </Container>
 

   
  )
}

export default Header
